<template>
  <div>
    <div class="pageMain">
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
      >
      
        <el-form-item label="日期：">
          <el-date-picker @change="changedatepicker($event)" value-format="yyyy-MM-dd" v-model="datePicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="仓库：">
            <el-select v-model="searchForm.warehouseName" filterable placeholder="请选择仓库" style="width: 150px">
              <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="采购员：">
          <el-input
            v-model="searchForm.buyer"
            placeholder="请输入采购员"
          ></el-input>
        </el-form-item>
        <el-form-item label="付款状态：">
          <el-select v-model="searchForm.isPay" placeholder="请选择付款状态">
            <el-option v-for="(item, index) in statusList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">退货单列表</p>

        <template>
            <div class="tableTopBtn">
              <!-- v-if="addButton == '1'" -->
              <el-button @click="add" type="primary" class="el-button--mini"><i class="el-icon-plus"></i> 新增退货单</el-button>
              <el-button @click="del" type="danger" class="el-button--mini"><i class="el-icon-delete" v-if="delButton=='1'"></i> 批量删除</el-button>
            </div>
          <el-table ref="multipleTable" :data="tableData" :stripe="true" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" >
            </el-table-column>
            <el-table-column prop="id" label="编号" min-width="140px">
              <template slot-scope="scope">
                <p class="numberId" @click="$router.push({ name: 'purchaseReturnForm', params: { type: 'edit', id: scope.row.id }})">{{scope.row.id}}</p>
              </template>
            </el-table-column>
            <el-table-column prop="warehouseName" label="仓库">
            </el-table-column>
            <el-table-column prop="goodsName" label="商品" show-overflow-tooltip>
              <template slot-scope="scope">
                {{
                  scope.row.purchaseChildReturnOrderList
                    ? scope.row.purchaseChildReturnOrderList
                        .map((item) => item.goodsName + "*" + item.num)
                        .join(",")
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="采购总价">
              <template slot-scope="scope">
                {{ scope.row.totalPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="isPay" label="付款状态">
              <template slot-scope="scope">
                {{ scope.row.isPay == 0 ? "未付款" : "已付款" }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="isTopCarriage" label="上架状态">
              <template slot-scope="scope">
                {{
                  scope.row.isTopCarriage == 0
                    ? "未上架"
                    : scope.row.isTopCarriage == 1
                    ? "已上架"
                    : "已下架"
                }}
              </template>
            </el-table-column> -->
            <!-- <el-table-column prop="status" label="审核状态">
              <template slot-scope="scope">
                {{
                  scope.row.status == 0
                    ? "待审核"
                    : scope.row.status == 1
                    ? "审核通过"
                    : "审核拒绝"
                }}
              </template>
            </el-table-column> -->
            <el-table-column prop="buyer" label="采购人"> </el-table-column>
            <el-table-column prop="arrivalTime" label="到货时间" width="170">
            </el-table-column>
            <el-table-column prop="freight" label="运费">
              <template slot-scope="scope">
                {{ scope.row.freight }} 元
              </template>
            </el-table-column>
            <!-- <el-table-column prop="status" label="上架状态">
                <template slot-scope="scope">
                  {{
                    scope.row.status == 0
                    ? "未上架"
                    : scope.row.status == 1
                      ? "已上架"
                      : "已下架"
                  }}
                </template>
            </el-table-column> -->
            <el-table-column align="center" label="操作" width="220">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="showDetail(scope.row)">详情</el-button>
                  <el-button size="mini" type="primary" @click="edit(scope.row)" v-if="updateButton == '1'" :disabled="scope.row.status == 9">编辑</el-button>
                  <!-- <el-button size="mini" type="danger" @click="del(1, scope.row.id)" :disabled="scope.row.status == 1" v-if="delButton == '1'">删除</el-button> -->
                </template>
              </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
//vue实例
export default {
  data() {
    return {
      loading: true,
      //搜索
      datePicker: [],
      searchForm: {
        isPay: "",
        buyer:"",
        startTime:"",
        endTime:"",
      },
      tableData: [],
      tabWidth: 200,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 1000,
        rows: [1000, 5000, 10000, 100000],
      },
      statusList: [
        //审核状态
        {
          name: "未退款",
          id: 0,
        },
        {
          name: "已付款",
          id: 1,
        }
      ],
      exportButton:'1',
      numSum:'',
      priceSum:'',
      warehouseList:[],
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton:'',
      ids:[],
    };
  },
  created() {
    this.listEvent();
    this.getWarehouse();

    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "purchase_return_order_update") {
        this.updateButton = "1";
      } else if (item.authority == "purchase_return_order_query") {
        this.searchButton = "1";
      } else if (item.authority == "purchase_return_order_add") {
        this.addButton = "1";
      } else if (item.authority == "purchase_return_order_delete") {
        this.delButton = "1";
      }
      
    });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "listEvent",
  },
  methods: {
    // 新增退货单
    add() {
      this.$router.push({ name: 'purchaseReturnForm', params: { type: 'add' } })
    },
    //查看详情
    showDetail(row) {
      this.$router.push({ name: 'purchaseReturnForm', params: { type: 'details', id: row.id } })
    },
    //编辑
    edit(row) {
      this.$router.push({ name: 'purchaseReturnForm', params: { type: 'edit', id: row.id } })
    },



     //GET 仓库
    getWarehouse() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.warehouseList = res.data.data;
          }
        });
    },
    changedatepicker(e) {
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    },
    listEvent() {
      var _this = this;
      _this.searchForm.size = _this.pagesData.currentRows;
      _this.searchForm.current = _this.pagesData.currentPage;
      //获取列表
      this.$axios
        .get(_this.$axios.defaults.basePath + "/purchaseReturnOrder", {
          params: _this.searchForm,
        })
        .then(function (res) {
          var resData = res.data;
          if (res.data.errcode == 0) {
            _this.loading = false;
            _this.tableData = resData.data.page.records;
            _this.pagesData.total = resData.data.page.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //侧边栏伸缩
    openAside() {
      if (this.isCollapse) {
        this.isCollapse = false;
        this.tabWidth = 200;
      } else {
        this.isCollapse = true;
        this.tabWidth = 64;
      }
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm(formName) {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    del(){
      let _this = this;
      this.$confirm("是否确认删除数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/purchaseReturnOrder/delete", {
              params: {
                ids: _this.ids.join(","),
              },
            })
            .then((res) => {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "操作成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "warning",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped>
.totalNum{
    display: flex;
    justify-content: space-between;
    margin: 0px 50px 10px 20px;
    font-weight: 550;
}
</style>